import c901 from "../images/cards/901.png";
import c902 from "../images/cards/902.png";
import c903 from "../images/cards/903.png";
import c904 from "../images/cards/904.png";
import c905 from "../images/cards/905.png";
import c906 from "../images/cards/906.png";
import c907 from "../images/cards/907.png";
import c908 from "../images/cards/908.png";
import c909 from "../images/cards/909.png";
import c910 from "../images/cards/910.png";
import c911 from "../images/cards/911.png";
import c912 from "../images/cards/912.png";
import c913 from "../images/cards/913.png";
import c914 from "../images/cards/914.png";
import c915 from "../images/cards/915.png";
import c916 from "../images/cards/916.png";
import c917 from "../images/cards/917.png";
import c918 from "../images/cards/918.png";
import c919 from "../images/cards/919.png";
import c920 from "../images/cards/920.png";
import c921 from "../images/cards/921.png";
import c922 from "../images/cards/922.png";
import c923 from "../images/cards/923.png";
import c924 from "../images/cards/924.png";
import c925 from "../images/cards/925.png";

export const points = {
  901: { image: c901 },
  902: { image: c902 },
  903: { image: c903 },
  904: { image: c904 },
  905: { image: c905 },
  906: { image: c906 },
  907: { image: c907 },
  908: { image: c908 },
  909: { image: c909 },
  910: { image: c910 },
  911: { image: c911 },
  912: { image: c912 },
  913: { image: c913 },
  914: { image: c914 },
  915: { image: c915 },
  916: { image: c916 },
  917: { image: c917 },
  918: { image: c918 },
  919: { image: c919 },
  920: { image: c920 },
  921: { image: c921 },
  922: { image: c922 },
  923: { image: c923 },
  924: { image: c924 },
  925: { image: c925 },
};
