import * as React from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import FarmFieldImage from "../images/farmField.png";
import InvBarley from "../images/inv-barley.png";
import InvFlax from "../images/inv-flax.png";
import InvWheat from "../images/inv-wheat.png";
import InvHops from "../images/inv-hops.png";
import InvMilk from "../images/inv-milk.png";
import InvMeat from "../images/inv-meat.png";
import InvFur from "../images/inv-fur.png";
import InvWool from "../images/inv-wool.png";
import InvBrick from "../images/inv-brick.png";
import FarmHarvest from "../images/farm-harvest.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GameIdContext } from "../App";
export const FarmField = (props) => {
  const [topMarginOffset, setTopMarginOffset] = React.useState(0);
  const [opacity, setOpacity] = React.useState(0);
  const [showExtra, setShowExtra] = React.useState(false);

  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";

  let fieldLevel = props.level;

  const setMargins = () => {
    if (fieldLevel === 5) {
      if (topMarginOffset !== 0) {
        setTopMarginOffset(0);
      }
      if (opacity !== 1) {
        setOpacity(1);
      }
    } else if (fieldLevel === 4) {
      if (topMarginOffset !== 76) {
        setTopMarginOffset(76);
      }
      if (opacity !== 1) {
        setOpacity(1);
      }
    } else if (fieldLevel === 3) {
      if (topMarginOffset !== 150) {
        setTopMarginOffset(150);
      }
      if (opacity !== 1) {
        setOpacity(1);
      }
    } else if (fieldLevel === 2) {
      if (topMarginOffset !== 218) {
        setTopMarginOffset(218);
      }
      if (opacity !== 1) {
        setOpacity(1);
      }
    } else if (fieldLevel === 1) {
      if (topMarginOffset !== 250) {
        setTopMarginOffset(250);
      }
      if (opacity !== 0) {
        setOpacity(0);
      }
    }
  };
  setMargins();

  const plantedCropImage = () => {
    if (props.crop === "barley") {
      return InvBarley;
    }
    if (props.crop === "flax") {
      return InvFlax;
    }
    if (props.crop === "wheat") {
      return InvWheat;
    }
    if (props.crop === "hops") {
      return InvHops;
    }
    if (props.crop === "milk") {
      return InvMilk;
    }
    if (props.crop === "fur") {
      return InvFur;
    }
    if (props.crop === "meat") {
      return InvMeat;
    }
    if (props.crop === "wool") {
      return InvWool;
    }
    if (props.crop === "brick") {
      return InvBrick;
    }
  };

  const handlePlant = (crop) => {
    let plotNumber = `plot${props.plot}`;
    let playerData = {
      [props.player]: {
        farm: {
          [plotNumber]: {
            crop: crop,
          },
        },
      },
    };
    setDoc(gameRef, { playerData }, { merge: true });
  };

  const handleHarvest = () => {
    let plotNumber = `plot${props.plot}`;
    let playerData = {
      [props.player]: {
        farm: {
          [plotNumber]: {
            crop: "",
          },
        },
      },
    };
    setDoc(gameRef, { playerData }, { merge: true });
  };

  const handleExtra = () => {
    if (!showExtra) {
      setShowExtra(true);
    }
  };

  const handleCloseExtra = () => {
    if (showExtra) {
      setShowExtra(false);
    }
  };

  return (
    <div
      className={`farmField`}
      style={{ marginTop: topMarginOffset + "px", opacity: opacity }}
    >
      <div className="plantActions">
        <div className="plantCrops">
          {!props.crop && !showExtra && (
            <>
              <button
                onClick={() => {
                  handlePlant("barley");
                }}
                className="cropButton"
              >
                <img src={InvBarley} />
              </button>
              <button
                onClick={() => {
                  handlePlant("flax");
                }}
                className="cropButton"
              >
                <img src={InvFlax} />
              </button>
              <button
                onClick={() => {
                  handlePlant("wheat");
                }}
                className="cropButton"
              >
                <img src={InvWheat} />
              </button>
              <button
                onClick={() => {
                  handlePlant("hops");
                }}
                className="cropButton"
              >
                <img src={InvHops} />
              </button>
              <button onClick={handleExtra} className="cropButton">
                ...
              </button>
            </>
          )}
          {!props.crop && showExtra && (
            <>
              <button onClick={handleCloseExtra} className="cropButton">
                <ArrowBackIcon />
              </button>
              <button
                onClick={() => {
                  handlePlant("milk");
                }}
                className="cropButton"
              >
                <img src={InvMilk} />
              </button>
              <button
                onClick={() => {
                  handlePlant("meat");
                }}
                className="cropButton"
              >
                <img src={InvMeat} />
              </button>
              <button
                onClick={() => {
                  handlePlant("wool");
                }}
                className="cropButton"
              >
                <img src={InvWool} />
              </button>
              <button
                onClick={() => {
                  handlePlant("fur");
                }}
                className="cropButton"
              >
                <img src={InvFur} />
              </button>
              <button
                onClick={() => {
                  handlePlant("brick");
                }}
                className="cropButton"
              >
                <img src={InvBrick} />
              </button>
            </>
          )}
          {props.crop && (
            <button onClick={handleHarvest} className="cropButton harvest">
              <img src={FarmHarvest} />
            </button>
          )}
        </div>
      </div>
      {props.crop && (
        <div className="plantedCrop">
          <img src={plantedCropImage()} />
        </div>
      )}
      <img className="field" src={FarmFieldImage} />
    </div>
  );
};
