import * as React from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import HomeHouseImage from "../images/home-house.png";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/ArrowLeft";
import AddIcon from "@mui/icons-material/ArrowRight";
import SheepCard from "../images/sheep-card-back.png";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import AddWorkerIcon from "@mui/icons-material/Add";
import RemoveWorkerIcon from "@mui/icons-material/Remove";
import ResetWorkersIcon from "@mui/icons-material/RestartAlt";
import _ from "lodash";
import { moveCards } from "../utils/helpers";
import { Worker } from "./Worker";
import { GameIdContext } from "../App";

export const HomeHouse = (props) => {
  const homeInfo = props.gameState?.playerData?.[props.player]?.home;
  const workersInfo = props.gameState?.playerData?.[props.player]?.workers;
  const cardArray = props.gameState?.["deckFarmyard"];
  const playerCardsArray =
    props.gameState?.playerData?.[props.player]?.cards?.unplayed;
  const [levelMargin, setLevelMargin] = React.useState(0);
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";
  const playerHome = props.gameState?.playerData?.[props.player]?.home;
  const window = playerHome?.window;

  const getMinBuildingPosition = () => {
    let buildingsArray = [];
    buildingsArray.push(playerHome?.bakehouse);
    buildingsArray.push(playerHome?.brewhouse);
    buildingsArray.push(playerHome?.coolinghouse);
    buildingsArray.push(playerHome?.carpentry);
    buildingsArray.push(playerHome?.manufacture);
    let sortedArray = buildingsArray.sort(function (a, b) {
      return a.level - b.level;
    });
    return sortedArray?.[0]?.level;
  };

  const setHouseMargin = () => {
    if (window === 6) {
      if (levelMargin !== 0) {
        setLevelMargin(0);
      }
    } else if (window === 7) {
      if (levelMargin !== 39) {
        setLevelMargin(39);
      }
    } else if (window === 8) {
      if (levelMargin !== 77) {
        setLevelMargin(77);
      }
    } else if (window === 9) {
      if (levelMargin !== 115) {
        setLevelMargin(115);
      }
    } else if (window === 10) {
      if (levelMargin !== 154) {
        setLevelMargin(154);
      }
    } else if (window === 11) {
      if (levelMargin !== 190) {
        setLevelMargin(190);
      }
    } else if (window === 12) {
      if (levelMargin !== 230) {
        setLevelMargin(230);
      }
    } else if (window === 13) {
      if (levelMargin !== 269) {
        setLevelMargin(269);
      }
    } else if (window === 14) {
      if (levelMargin !== 308) {
        setLevelMargin(308);
      }
    } else if (window === 15) {
      if (levelMargin !== 345) {
        setLevelMargin(345);
      }
    } else if (window === 16) {
      if (levelMargin !== 384) {
        setLevelMargin(384);
      }
    }
  };
  setHouseMargin();
  const handleUpgrade = () => {
    if (window < 16 && window - getMinBuildingPosition() < 6) {
      let newAmount = window + 1;
      let playerData = {
        [props.player]: {
          home: {
            window: newAmount,
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };
  const handleDowngrade = () => {
    if (window > 6) {
      let newAmount = window - 1;
      let playerData = {
        [props.player]: {
          home: {
            window: newAmount,
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  const handleCardClick = () => {
    if (homeInfo.card) {
      // get new arrays
      const clonedCardArray = _.cloneDeep(cardArray);
      const clonedPlayerCardsArray = _.cloneDeep(playerCardsArray);
      moveCards(clonedCardArray, clonedPlayerCardsArray, 1);
      let deckFarmyard = clonedCardArray;
      let playerData = {
        [props.player]: {
          cards: {
            unplayed: clonedPlayerCardsArray,
          },
          home: {
            card: false,
          },
        },
      };
      setDoc(gameRef, { deckFarmyard, playerData }, { merge: true });
    }
  };

  const handleResetWorkers = () => {
    let newAmount = window;
    let playerData = {
      [props.player]: {
        workers: {
          current: newAmount,
        },
      },
    };
    setDoc(gameRef, { playerData }, { merge: true });
  };

  const handleAddWorker = () => {
    let newAmount = workersInfo.current + 1;
    let playerData = {
      [props.player]: {
        workers: {
          current: newAmount,
        },
      },
    };
    setDoc(gameRef, { playerData }, { merge: true });
  };

  const handleRemoveWorker = () => {
    if (workersInfo.current > 0) {
      let newAmount = workersInfo.current - 1;
      let playerData = {
        [props.player]: {
          workers: {
            current: newAmount,
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  return (
    <div className="house" style={{ marginLeft: levelMargin + "px" }}>
      {homeInfo?.card && (
        <div className="card animate splat" onClick={handleCardClick}>
          <div className="control">
            <AutoModeIcon fontSize="large" />
          </div>
          <img src={SheepCard} />
        </div>
      )}
      <div className="workers">
        <div className="workerControls">
          <ButtonGroup className="modifyButtonGroup">
            <Button aria-label="reset" onClick={handleResetWorkers}>
              <ResetWorkersIcon fontSize="small" />
            </Button>
            <Button aria-label="remove" onClick={handleRemoveWorker}>
              <RemoveWorkerIcon fontSize="small" />
            </Button>
            <Button aria-label="addworker" onClick={handleAddWorker}>
              <AddWorkerIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </div>
        {[...Array(workersInfo?.current)].map((e, i) => (
          <Worker key={i} scatter={true} />
        ))}
      </div>
      <div className="controls">
        <ButtonGroup className="modifyButtonGroup">
          <Button aria-label="reduce" onClick={handleDowngrade}>
            <RemoveIcon fontSize="small" />
          </Button>
          <Button aria-label="increase" onClick={handleUpgrade}>
            <AddIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      </div>
      <img className="houseImage" src={HomeHouseImage} />
    </div>
  );
};
