import * as React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/ArrowDropUp";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { GameIdContext } from "../App";

export const FarmFieldControls = (props) => {
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";

  const handleAdd = () => {
    if (props.level < 5) {
      let newAmount = props.level + 1;
      let plotNumber = `plot${props.plot}`;
      let playerData = {
        [props.player]: {
          farm: {
            [plotNumber]: {
              level: newAmount,
            },
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  const handleRemove = () => {
    if (props.level > 1) {
      let newAmount = props.level - 1;
      let plotNumber = `plot${props.plot}`;
      let playerData = {
        [props.player]: {
          farm: {
            [plotNumber]: {
              level: newAmount,
            },
          },
        },
      };
      if (newAmount < 2) {
        playerData[props.player].farm[plotNumber].crop = "";
      }
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  return (
    <div className="farmFieldControls">
      <ButtonGroup className="modifyButtonGroup">
        <Button aria-label="reduce" onClick={handleRemove}>
          <RemoveIcon fontSize="small" />
        </Button>
        <Button aria-label="increase" onClick={handleAdd}>
          <AddIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    </div>
  );
};
