import * as React from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import BoardSheep from "../images/board-sheep.png";
import { SheepSlot } from "./SheepSlot";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/AddCircle";
import SheepImage from "../images/sheep.png";
import shortid from "shortid";
import { GameIdContext } from "../App";

export const Sheep = (props) => {
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";
  const stableInfo = props.gameState?.playerData?.[props.player]?.sheep?.[7];
  const stableSheep = stableInfo?.sheep;
  const handleRemoveSheep = () => {
    if (stableSheep > 0) {
      let newAmount = stableSheep - 1;
      let playerData = {
        [props.player]: {
          sheep: {
            [7]: {
              sheep: newAmount,
            },
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  const handleAddSheep = () => {
    let newAmount = stableSheep + 1;
    let playerData = {
      [props.player]: {
        sheep: {
          [7]: {
            sheep: newAmount,
          },
        },
      },
    };
    setDoc(gameRef, { playerData }, { merge: true });
  };

  return (
    <div className="sheep">
      <div className="sheepSlots">
        <SheepSlot
          round={1}
          gameState={props.gameState}
          player={props.player}
        />
        <SheepSlot
          round={2}
          gameState={props.gameState}
          player={props.player}
        />
        <SheepSlot
          round={3}
          gameState={props.gameState}
          player={props.player}
        />
        <SheepSlot
          round={4}
          gameState={props.gameState}
          player={props.player}
        />
        <SheepSlot
          round={5}
          gameState={props.gameState}
          player={props.player}
        />
        <SheepSlot
          round={6}
          gameState={props.gameState}
          player={props.player}
        />
      </div>
      <div className="stable">
        <div className="controls">
          <ButtonGroup className="modifyButtonGroup">
            <Button aria-label="reduce" onClick={handleRemoveSheep}>
              <RemoveIcon fontSize="small" />
            </Button>
            <Button aria-label="increase" onClick={handleAddSheep}>
              <AddIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </div>
        <div className="sheepGroup">
          {[...Array(stableInfo?.sheep)].map((sheep, index) => (
            <img key={index} className="sheep animate splat" src={SheepImage} />
          ))}
        </div>
      </div>

      <img className="board" src={BoardSheep} />
    </div>
  );
};
