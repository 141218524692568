import { farmyardField } from "../cards/farmyardField";
import { farmyardHops } from "../cards/farmyardHops";
import { farmyardJewerly } from "../cards/farmyardJewerly";
import { gatewayBeginner } from "../cards/gateway-beginner";
import { gatewayAdvanced } from "../cards/gateway-advanced";
import { gatewayExpert } from "../cards/gateway-expert";
import { gatewayMaster } from "../cards/gateway-master";
import { market } from "../cards/market";
import { points } from "../cards/points";
import { farmyardSheep } from "./farmyardSheep";

export const allCards = {
  ...gatewayBeginner,
  ...gatewayAdvanced,
  ...gatewayExpert,
  ...gatewayMaster,
  ...farmyardHops,
  ...farmyardSheep,
  ...farmyardField,
  ...farmyardJewerly,
  ...market,
  ...points,
};
