import c801 from "../images/cards/801.png";
import c802 from "../images/cards/802.png";
import c803 from "../images/cards/803.png";
import c804 from "../images/cards/804.png";
import c805 from "../images/cards/805.png";
import c806 from "../images/cards/806.png";
import c807 from "../images/cards/807.png";
import c808 from "../images/cards/808.png";
import c809 from "../images/cards/809.png";
import c810 from "../images/cards/810.png";
import c811 from "../images/cards/811.png";
import c812 from "../images/cards/812.png";
import c813 from "../images/cards/813.png";
import c814 from "../images/cards/814.png";
import c815 from "../images/cards/815.png";
import c816 from "../images/cards/816.png";
import c817 from "../images/cards/817.png";
import c818 from "../images/cards/818.png";
import c819 from "../images/cards/819.png";
import c820 from "../images/cards/820.png";
import c821 from "../images/cards/821.png";
import c822 from "../images/cards/822.png";
import c823 from "../images/cards/823.png";
import c824 from "../images/cards/824.png";
import c825 from "../images/cards/825.png";
import c826 from "../images/cards/826.png";
import c827 from "../images/cards/827.png";
import c828 from "../images/cards/828.png";
import c829 from "../images/cards/829.png";
import c830 from "../images/cards/830.png";
import c831 from "../images/cards/831.png";
import c832 from "../images/cards/832.png";
import c833 from "../images/cards/833.png";
import c834 from "../images/cards/834.png";
import c835 from "../images/cards/835.png";
import c836 from "../images/cards/836.png";
import c837 from "../images/cards/837.png";
import c838 from "../images/cards/838.png";
import c839 from "../images/cards/839.png";
import c840 from "../images/cards/840.png";
import c841 from "../images/cards/841.png";
import c842 from "../images/cards/842.png";
import c843 from "../images/cards/843.png";
import c844 from "../images/cards/844.png";
import c845 from "../images/cards/845.png";

export const market = {
  801: { image: c801 },
  802: { image: c802 },
  803: { image: c803 },
  804: { image: c804 },
  805: { image: c805 },
  806: { image: c806 },
  807: { image: c807 },
  808: { image: c808 },
  809: { image: c809 },
  810: { image: c810 },
  811: { image: c811 },
  812: { image: c812 },
  813: { image: c813 },
  814: { image: c814 },
  815: { image: c815 },
  816: { image: c816 },
  817: { image: c817 },
  818: { image: c818 },
  819: { image: c819 },
  820: { image: c820 },
  821: { image: c821 },
  822: { image: c822 },
  823: { image: c823 },
  824: { image: c824 },
  825: { image: c825 },
  826: { image: c826 },
  827: { image: c827 },
  828: { image: c828 },
  829: { image: c829 },
  830: { image: c830 },
  831: { image: c831 },
  832: { image: c832 },
  833: { image: c833 },
  834: { image: c834 },
  835: { image: c835 },
  836: { image: c836 },
  837: { image: c837 },
  838: { image: c838 },
  839: { image: c839 },
  840: { image: c840 },
  841: { image: c841 },
  842: { image: c842 },
  843: { image: c843 },
  844: { image: c844 },
  845: { image: c845 },
};
