import c601 from "../images/cards/601.png";
import c602 from "../images/cards/602.png";
import c603 from "../images/cards/603.png";
import c604 from "../images/cards/604.png";
import c605 from "../images/cards/605.png";
import c606 from "../images/cards/606.png";
import c607 from "../images/cards/607.png";
import c608 from "../images/cards/608.png";
import c609 from "../images/cards/609.png";
import c610 from "../images/cards/610.png";
import c611 from "../images/cards/611.png";
import c612 from "../images/cards/612.png";
import c613 from "../images/cards/613.png";
import c614 from "../images/cards/614.png";
import c615 from "../images/cards/615.png";
import c616 from "../images/cards/616.png";
import c617 from "../images/cards/617.png";
import c618 from "../images/cards/618.png";
import c619 from "../images/cards/619.png";
import c620 from "../images/cards/620.png";
import c621 from "../images/cards/621.png";
import c622 from "../images/cards/622.png";
import c623 from "../images/cards/623.png";
import c624 from "../images/cards/624.png";
import c625 from "../images/cards/625.png";
import c626 from "../images/cards/626.png";
import c627 from "../images/cards/627.png";
import c628 from "../images/cards/628.png";
import c629 from "../images/cards/629.png";
import c630 from "../images/cards/630.png";
import c631 from "../images/cards/631.png";
import c632 from "../images/cards/632.png";
import c633 from "../images/cards/633.png";
import c634 from "../images/cards/634.png";
import c635 from "../images/cards/635.png";

export const farmyardField = {
  601: { image: c601 },
  602: { image: c602 },
  603: { image: c603 },
  604: { image: c604 },
  605: { image: c605 },
  606: { image: c606 },
  607: { image: c607 },
  608: { image: c608 },
  609: { image: c609 },
  610: { image: c610 },
  611: { image: c611 },
  612: { image: c612 },
  613: { image: c613 },
  614: { image: c614 },
  615: { image: c615 },
  616: { image: c616 },
  617: { image: c617 },
  618: { image: c618 },
  619: { image: c619 },
  620: { image: c620 },
  621: { image: c621 },
  622: { image: c622 },
  623: { image: c623 },
  624: { image: c624 },
  625: { image: c625 },
  626: { image: c626 },
  627: { image: c627 },
  628: { image: c628 },
  629: { image: c629 },
  630: { image: c630 },
  631: { image: c631 },
  632: { image: c632 },
  633: { image: c633 },
  634: { image: c634 },
  635: { image: c635 },
};
