import * as React from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import SheepCard from "../images/sheep-card-back.png";
import SheepImage from "../images/sheep.png";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/AddCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { GameIdContext } from "../App";

export const SheepSlot = (props) => {
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";
  const playerData = props.gameState?.playerData;
  const slotInfo =
    props.gameState?.playerData?.[props.player]?.sheep?.[props.round];
  const homeInfo = props.gameState?.playerData?.[props.player]?.home;
  const sheep = slotInfo?.sheep;

  const handleTransferCard = () => {
    if (slotInfo?.card && !homeInfo?.card) {
      let playerData = {
        [props.player]: {
          home: {
            card: true,
          },
          sheep: {
            [props.round]: {
              card: false,
              sheep: 0,
            },
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  const handleAddSheep = () => {
    if (slotInfo?.card) {
      let newAmount = sheep + 1;
      let playerData = {
        [props.player]: {
          sheep: {
            [props.round]: {
              sheep: newAmount,
            },
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  const handleRemoveSheep = () => {
    if (sheep > 0) {
      let newAmount = sheep - 1;
      let playerData = {
        [props.player]: {
          sheep: {
            [props.round]: {
              sheep: newAmount,
            },
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  return (
    <div className="sheepSlot">
      {slotInfo?.card && (
        <div className="controls">
          <ButtonGroup className="modifyButtonGroup">
            <Button
              disabled={
                !(
                  !playerData?.[props.player]?.sheep?.[props.round - 1]?.card ||
                  props.round === 1
                ) && !homeInfo?.card
              }
              className="transfer"
              aria-label="removeCard"
              onClick={handleTransferCard}
            >
              <LogoutIcon fontSize="small" />
            </Button>

            <Button aria-label="reduce" onClick={handleRemoveSheep}>
              <RemoveIcon fontSize="small" />
            </Button>
            <Button aria-label="increase" onClick={handleAddSheep}>
              <AddIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div className="sheepGroup">
        {[...Array(slotInfo?.sheep)].map((sheep, index) => (
          <img key={index} className="sheep animate splat" src={SheepImage} />
        ))}
      </div>
      {slotInfo?.card && <img className="card" src={SheepCard} />}
    </div>
  );
};
