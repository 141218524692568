import * as React from "react";
import "./App.scss";
import TableImage from "./images/table.jpg";
import { MainNav } from "./components/MainNav";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase/firebase";
import { Farm } from "./components/Farm";
import { Home } from "./components/Home";
import { Sheep } from "./components/Sheep";
import { Cards } from "./components/Cards";
import { ActionBoard } from "./components/ActionBoard";

// use default theme
// const theme = createTheme();

// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: "#000F",
    },
  },
});

export const GameIdContext = React.createContext();

function App() {
  const [gameId, setGameId] = React.useState("");
  const [gameState, setGameState] = React.useState({});
  const [player, setPlayer] = React.useState(1);
  const [showActionBoard, setShowActionBoard] = React.useState(false);
  const [showSizeWarning, setShowSizeWarning] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [zoomRatio, setZoomRatio] = React.useState(1);

  React.useEffect(() => {
    if (gameId) {
      const unsub = onSnapshot(doc(db, "games", gameId), (doc) => {
        if (JSON.stringify(gameState) !== JSON.stringify(doc.data())) {
          setGameState(doc.data());
        }
      });
      return unsub;
    }
  }, [gameId]);

  React.useEffect(() => {}, [gameState]);

  const spaceDownHandler = ({ key }) => {
    if (key === "`") {
      setShowActionBoard((freshState) => !freshState);
    }
  };

  const onresize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setWidth(width);
    setHeight(height);
    const widthRatio = width / 1720;
    const heightRatio = height / 895;
    if (widthRatio < heightRatio) {
      setZoomRatio(widthRatio);
    } else {
      setZoomRatio(heightRatio);
    }
  };

  React.useEffect(() => {
    onresize();
    window.addEventListener("keydown", spaceDownHandler);
    //window.addEventListener("keyup", spaceUpHandler);
    return () => {
      window.removeEventListener("keydown", spaceDownHandler);
      //window.removeEventListener("keyup", spaceUpHandler);
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", onresize);
    return () => {
      window.removeEventListener("resize", onresize);
    };
  }, [width, height]);

  return (
    <GameIdContext.Provider value={gameId}>
      <MuiThemeProvider theme={theme}>
        <div className="App" style={{ zoom: zoomRatio }}>
          {showSizeWarning && (
            <div className="sizeWarning">
              <h2>Your window is too small to play (Min 1720 x 900).</h2>
              <p>
                Please increase your window size, or{" "}
                <strong>use Cmd+'-' to zoom out.</strong>
              </p>
              <p>
                On an iPad, you can double-tap with three fingers to zoom out.
              </p>
              <p>
                (Your current window size is: {width}px x {height}px)
              </p>
            </div>
          )}
          <ActionBoard
            gameId={gameId}
            gameState={gameState}
            open={showActionBoard}
            setShowActionBoard={setShowActionBoard}
            player={player}
          />
          <MainNav
            gameState={gameState}
            setGameId={setGameId}
            setPlayer={setPlayer}
            player={player}
          />
          <div className="boards">
            <Farm gameState={gameState} player={player} />
            <Home gameState={gameState} player={player} />
          </div>
          <div className="sheepBoard">
            <Sheep gameState={gameState} player={player} gameId={gameId} />
          </div>
          <div className="cards">
            <Cards gameState={gameState} player={player} />
          </div>
          <div className="playOrder">
            <div className="left">
              <div className="step">1. Remove Workers</div>
              <div className="step">2. New Workers + House Card</div>
              <div className="step">3. Residual Income</div>
              <div className="step">4. Action Board</div>
              <div className="step">5. Flip House Card</div>
            </div>
            <div className="right">
              <div className="step">6. Upgrade Empty Fields</div>
              <div className="step">7. Harvest</div>
              <div className="step">8. Milk</div>
              <div className="step">9. Progress</div>
              <div className="step">10. Reset Boulders</div>
            </div>
          </div>
          <img className="background" src={TableImage} />
        </div>
      </MuiThemeProvider>
    </GameIdContext.Provider>
  );
}

export default App;
