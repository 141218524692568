import * as React from "react";
import { Worker } from "./Worker";
import Button from "@mui/material/Button";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import RemoveWorkerIcon from "@mui/icons-material/Remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { capitalizeFirstLetter, moveCards } from "../utils/helpers";
import _ from "lodash";
import { GameIdContext } from "../App";

export const CardSquare = (props) => {
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";
  const cardArray =
    props.gameState?.[`deck${capitalizeFirstLetter(props.actionName)}`];
  const playerCardsArray =
    props.gameState?.playerData?.[props.player]?.cards?.unplayed;
  const squareLevel = props.gameState?.boardCards?.[props.actionName]?.occupied;
  const playerWorkers =
    props.gameState?.playerData?.[props.player]?.workers?.current;

  const handleUseSpot = () => {
    let newAmount = squareLevel + 1;
    let newWorkers = playerWorkers - newAmount;

    // get new arrays
    const clonedCardArray = _.cloneDeep(cardArray);
    const clonedPlayerCardsArray = _.cloneDeep(playerCardsArray);
    moveCards(clonedCardArray, clonedPlayerCardsArray, 1);

    if (squareLevel < 2 && newWorkers >= 0) {
      let boardData = {
        rooster: props.player,
        boardCards: {
          [props.actionName]: {
            occupied: newAmount,
          },
        },
        playerData: {
          [props.player]: {
            cards: {
              unplayed: clonedPlayerCardsArray,
            },
            workers: {
              current: newWorkers,
            },
          },
        },
      };
      boardData[`deck${capitalizeFirstLetter(props.actionName)}`] =
        clonedCardArray;
      setDoc(gameRef, boardData, { merge: true });
    }
  };

  const handleTakeCard = () => {
    // get new arrays
    const clonedCardArray = _.cloneDeep(cardArray);
    const clonedPlayerCardsArray = _.cloneDeep(playerCardsArray);
    moveCards(clonedCardArray, clonedPlayerCardsArray, 1);
    if (clonedCardArray.length > 0) {
      let boardData = {
        playerData: {
          [props.player]: {
            cards: {
              unplayed: clonedPlayerCardsArray,
            },
          },
        },
      };
      boardData[`deck${capitalizeFirstLetter(props.actionName)}`] =
        clonedCardArray;
      setDoc(gameRef, boardData, { merge: true });
    }
  };

  const handleRemoveRow = () => {
    if (squareLevel > 0) {
      let newAmount = squareLevel - 1;
      let boardData = {
        boardCards: {
          [props.actionName]: {
            occupied: newAmount,
          },
        },
      };
      setDoc(gameRef, boardData, { merge: true });
    }
  };
  return (
    <div
      className={`cardSquare ${props.actionName} ${
        props.actionName === "gateway" || props.actionName === "farmyard"
          ? "topCard"
          : "bottomCard"
      }`}
    >
      <div className="controls">
        <Button className="activate" aria-label="reset" onClick={handleUseSpot}>
          <AutoFixNormalIcon fontSize="small" />
        </Button>
        <Button
          className="takeCard"
          aria-label="reset"
          onClick={handleTakeCard}
        >
          <AddCircleIcon fontSize="small" />
        </Button>
        <Button
          className="remove"
          aria-label="remove"
          onClick={handleRemoveRow}
        >
          <RemoveWorkerIcon fontSize="small" />
        </Button>
      </div>
      {squareLevel > 1 && (
        <div className="row-two">
          <Worker />
          <Worker />
        </div>
      )}
      {squareLevel > 0 && (
        <div className="row-one">
          <Worker />
        </div>
      )}
    </div>
  );
};
