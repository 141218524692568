import * as React from "react";
import BoardFarm from "../images/farm.png";
import { FarmField } from "./FarmField";
import { FarmFieldControls } from "./FarmFieldControls";

export const Farm = (props) => {
  const playerFarm = props.gameState?.playerData?.[props.player]?.farm;
  return (
    <div className="farm">
      <div className="plots">
        <FarmField
          crop={playerFarm?.plot1?.crop}
          level={playerFarm?.plot1?.level}
          plot={1}
          player={props.player}
        />
        <FarmField
          crop={playerFarm?.plot2?.crop}
          level={playerFarm?.plot2?.level}
          plot={2}
          player={props.player}
        />
        <FarmField
          crop={playerFarm?.plot3?.crop}
          level={playerFarm?.plot3?.level}
          plot={3}
          player={props.player}
        />
        <FarmField
          crop={playerFarm?.plot4?.crop}
          level={playerFarm?.plot4?.level}
          plot={4}
          player={props.player}
        />
        <FarmField
          crop={playerFarm?.plot5?.crop}
          level={playerFarm?.plot5?.level}
          plot={5}
          player={props.player}
        />
        <FarmField
          crop={playerFarm?.plot6?.crop}
          level={playerFarm?.plot6?.level}
          plot={6}
          player={props.player}
        />
        <FarmField
          crop={playerFarm?.plot7?.crop}
          level={playerFarm?.plot7?.level}
          plot={7}
          player={props.player}
        />
        <FarmField
          crop={playerFarm?.plot8?.crop}
          level={playerFarm?.plot8?.level}
          plot={8}
          player={props.player}
        />
      </div>
      <img className="board" src={BoardFarm} />
      <div className="controls">
        <FarmFieldControls
          level={playerFarm?.plot1?.level}
          crop={playerFarm?.plot1?.crop}
          player={props.player}
          plot={1}
        />
        <FarmFieldControls
          level={playerFarm?.plot2?.level}
          crop={playerFarm?.plot2?.crop}
          player={props.player}
          plot={2}
        />
        <FarmFieldControls
          level={playerFarm?.plot3?.level}
          crop={playerFarm?.plot3?.crop}
          player={props.player}
          plot={3}
        />
        <FarmFieldControls
          level={playerFarm?.plot4?.level}
          crop={playerFarm?.plot4?.crop}
          player={props.player}
          plot={4}
        />
        <FarmFieldControls
          level={playerFarm?.plot5?.level}
          crop={playerFarm?.plot5?.crop}
          player={props.player}
          plot={5}
        />
        <FarmFieldControls
          level={playerFarm?.plot6?.level}
          crop={playerFarm?.plot?.crop}
          player={props.player}
          plot={6}
        />
        <FarmFieldControls
          level={playerFarm?.plot7?.level}
          crop={playerFarm?.plot7?.crop}
          player={props.player}
          plot={7}
        />
        <FarmFieldControls
          level={playerFarm?.plot8?.level}
          crop={playerFarm?.plot8?.crop}
          player={props.player}
          plot={8}
        />
      </div>
    </div>
  );
};
