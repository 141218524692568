import fp1 from "../images/cards/fewer-players-1.png";
import fp2 from "../images/cards/fewer-players-2.png";
import fp3 from "../images/cards/fewer-players-3.png";
import fp4 from "../images/cards/fewer-players-4.png";
import fp5 from "../images/cards/fewer-players-5.png";
import fp6 from "../images/cards/fewer-players-6.png";

export const fewerPlayers = {
  1: { image: fp1 },
  2: { image: fp2 },
  3: { image: fp3 },
  4: { image: fp4 },
  5: { image: fp5 },
  6: { image: fp6 },
};
