import c101 from "../images/cards/101.png";
import c102 from "../images/cards/102.png";
import c103 from "../images/cards/103.png";
import c104 from "../images/cards/104.png";
import c105 from "../images/cards/105.png";
import c106 from "../images/cards/106.png";
import c107 from "../images/cards/107.png";
import c108 from "../images/cards/108.png";
import c109 from "../images/cards/109.png";
import c110 from "../images/cards/110.png";
import c111 from "../images/cards/111.png";
import c112 from "../images/cards/112.png";
import c113 from "../images/cards/113.png";
import c114 from "../images/cards/114.png";
import c115 from "../images/cards/115.png";
import c116 from "../images/cards/116.png";
import c117 from "../images/cards/117.png";
import c118 from "../images/cards/118.png";
import c119 from "../images/cards/119.png";
import c120 from "../images/cards/120.png";
import c121 from "../images/cards/121.png";
import c122 from "../images/cards/122.png";
import c123 from "../images/cards/123.png";
import c124 from "../images/cards/124.png";
import c125 from "../images/cards/125.png";
import c126 from "../images/cards/126.png";
import c127 from "../images/cards/127.png";
import c128 from "../images/cards/128.png";
import c129 from "../images/cards/129.png";
import c130 from "../images/cards/130.png";

export const gatewayAdvanced = {
  101: { image: c101 },
  102: { image: c102 },
  103: { image: c103 },
  104: { image: c104 },
  105: { image: c105 },
  106: { image: c106 },
  107: { image: c107 },
  108: { image: c108 },
  109: { image: c109 },
  110: { image: c110 },
  111: { image: c111 },
  112: { image: c112 },
  113: { image: c113 },
  114: { image: c114 },
  115: { image: c115 },
  116: { image: c116 },
  117: { image: c117 },
  118: { image: c118 },
  119: { image: c119 },
  120: { image: c120 },
  121: { image: c121 },
  122: { image: c122 },
  123: { image: c123 },
  124: { image: c124 },
  125: { image: c125 },
  126: { image: c126 },
  127: { image: c127 },
  128: { image: c128 },
  129: { image: c129 },
  130: { image: c130 },
};
