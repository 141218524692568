// JavaScript
// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const useLocalFirestore =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

// prod database

const firebaseConfig = {
  apiKey: "AIzaSyBii1wTmf-zoeAF0CrIrGRQIt2Hzwbun9I",
  authDomain: "hallertau-f1d2a.firebaseapp.com",
  projectId: "hallertau-f1d2a",
  storageBucket: "hallertau-f1d2a.appspot.com",
  messagingSenderId: "926366629890",
  appId: "1:926366629890:web:6f2779a1e3f5a37637a71b",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

/*
if (useLocalFirestore) {
  connectFirestoreEmulator(db, "localhost", 8080);
}
*/

// firebaseApps previously initialized using initializeApp()

export { db };
