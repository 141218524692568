import * as React from "react";
import { Inventory } from "./Inventory";
import { MenuButton } from "./MenuButton";
import { PlayerButton } from "./PlayerButton";
import Logo from "../images/logo.png";
export const MainNav = (props) => {
  return (
    <div className="mainNav">
      <img className="logo" src={Logo} />
      <MenuButton setGameId={props.setGameId} gameState={props.gameState} />
      <PlayerButton gameState={props.gameState} setPlayer={props.setPlayer} />
      <Inventory gameState={props.gameState} player={props.player} />
    </div>
  );
};
