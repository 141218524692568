import c701 from "../images/cards/701.png";
import c702 from "../images/cards/702.png";
import c703 from "../images/cards/703.png";
import c704 from "../images/cards/704.png";
import c705 from "../images/cards/705.png";
import c706 from "../images/cards/706.png";
import c707 from "../images/cards/707.png";
import c708 from "../images/cards/708.png";
import c709 from "../images/cards/709.png";
import c710 from "../images/cards/710.png";
import c711 from "../images/cards/711.png";
import c712 from "../images/cards/712.png";
import c713 from "../images/cards/713.png";
import c714 from "../images/cards/714.png";
import c715 from "../images/cards/715.png";
import c716 from "../images/cards/716.png";
import c717 from "../images/cards/717.png";
import c718 from "../images/cards/718.png";
import c719 from "../images/cards/719.png";
import c720 from "../images/cards/720.png";
import c721 from "../images/cards/721.png";
import c722 from "../images/cards/722.png";
import c723 from "../images/cards/723.png";
import c724 from "../images/cards/724.png";
import c725 from "../images/cards/725.png";
import c726 from "../images/cards/726.png";
import c727 from "../images/cards/727.png";
import c728 from "../images/cards/728.png";
import c729 from "../images/cards/729.png";
import c730 from "../images/cards/730.png";
import c731 from "../images/cards/731.png";
import c732 from "../images/cards/732.png";
import c733 from "../images/cards/733.png";
import c734 from "../images/cards/734.png";
import c735 from "../images/cards/735.png";

export const farmyardJewerly = {
  701: { image: c701 },
  702: { image: c702 },
  703: { image: c703 },
  704: { image: c704 },
  705: { image: c705 },
  706: { image: c706 },
  707: { image: c707 },
  708: { image: c708 },
  709: { image: c709 },
  710: { image: c710 },
  711: { image: c711 },
  712: { image: c712 },
  713: { image: c713 },
  714: { image: c714 },
  715: { image: c715 },
  716: { image: c716 },
  717: { image: c717 },
  718: { image: c718 },
  719: { image: c719 },
  720: { image: c720 },
  721: { image: c721 },
  722: { image: c722 },
  723: { image: c723 },
  724: { image: c724 },
  725: { image: c725 },
  726: { image: c726 },
  727: { image: c727 },
  728: { image: c728 },
  729: { image: c729 },
  730: { image: c730 },
  731: { image: c731 },
  732: { image: c732 },
  733: { image: c733 },
  734: { image: c734 },
  735: { image: c735 },
};
