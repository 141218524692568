import * as React from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { allCards } from "../cards/allCards";
import { arrayMoveImmutable } from "array-move";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import _ from "lodash";
import { moveCards, sortCardsIntoCategory } from "../utils/helpers";
import { GameIdContext } from "../App";

export const CardSingle = (props) => {
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";
  const unplayedDeck =
    props.gameState?.playerData?.[props.player]?.cards?.unplayed;
  const usedDeck =
    props.gameState?.playerData?.[props.player]?.cards?.[
      sortCardsIntoCategory(props.cardId)
    ];
  const [image, setImage] = React.useState();
  React.useEffect(() => {
    setImage(allCards[props.cardId]);
  }, [props.player, props.gameState]);

  const handleMoveUp = () => {
    if (props.used) {
      if (props.position > 0) {
        const newArray = arrayMoveImmutable(
          usedDeck,
          props.position,
          props.position - 1
        );
        let playerData = {
          [props.player]: {
            cards: {
              [sortCardsIntoCategory(props.cardId)]: newArray,
            },
          },
        };
        setDoc(gameRef, { playerData }, { merge: true });
      }
    } else {
      if (props.position > 0) {
        const newArray = arrayMoveImmutable(
          unplayedDeck,
          props.position,
          props.position - 1
        );
        let playerData = {
          [props.player]: {
            cards: {
              unplayed: newArray,
            },
          },
        };
        setDoc(gameRef, { playerData }, { merge: true });
      }
    }
  };

  const handleMoveDown = () => {
    if (props.used) {
      if (props.position < usedDeck.length - 1) {
        const newArray = arrayMoveImmutable(
          usedDeck,
          props.position,
          props.position + 1
        );
        let playerData = {
          [props.player]: {
            cards: {
              [sortCardsIntoCategory(props.cardId)]: newArray,
            },
          },
        };
        setDoc(gameRef, { playerData }, { merge: true });
      }
    } else {
      if (props.position < unplayedDeck.length - 1) {
        const newArray = arrayMoveImmutable(
          unplayedDeck,
          props.position,
          props.position + 1
        );
        let playerData = {
          [props.player]: {
            cards: {
              unplayed: newArray,
            },
          },
        };
        setDoc(gameRef, { playerData }, { merge: true });
      }
    }
  };

  const handleUse = () => {
    const cardCategory = sortCardsIntoCategory(props.cardId);
    const indexInUnplayed = props.gameState?.playerData?.[
      props.player
    ]?.cards?.unplayed?.indexOf(props.cardId);
    const playerCardsArrayDest = _.cloneDeep(
      props.gameState?.playerData?.[props.player]?.cards?.[cardCategory]
    );
    const playerCardsArray = _.cloneDeep(
      props.gameState?.playerData?.[props.player]?.cards?.unplayed
    );
    // add to new category
    playerCardsArrayDest.push(props.cardId);

    // remove from unplayed
    playerCardsArray.splice(indexInUnplayed, 1);

    let playerData = {
      [props.player]: {
        cards: {
          unplayed: playerCardsArray,
          [cardCategory]: playerCardsArrayDest,
        },
      },
    };
    setDoc(gameRef, { playerData }, { merge: true });
  };

  const handleDelete = () => {
    const indexInUnplayed = props.gameState?.playerData?.[
      props.player
    ]?.cards?.unplayed?.indexOf(props.cardId);
    const playerCardsArray = _.cloneDeep(
      props.gameState?.playerData?.[props.player]?.cards?.unplayed
    );
    // remove from unplayed
    playerCardsArray.splice(indexInUnplayed, 1);

    let playerData = {
      [props.player]: {
        cards: {
          unplayed: playerCardsArray,
        },
      },
    };
    setDoc(gameRef, { playerData }, { merge: true });
  };

  return (
    <div className="cardsSingle animate">
      <div className="controls">
        <Button className="moveUp" aria-label="reset" onClick={handleMoveUp}>
          <ChevronLeftIcon fontSize="small" />
        </Button>
        <Button
          className="moveDown"
          aria-label="reset"
          onClick={handleMoveDown}
        >
          <ChevronRightIcon fontSize="small" />
        </Button>
        {!props.used && (
          <>
            <Button className="activate" aria-label="reset" onClick={handleUse}>
              <AutoFixNormalIcon fontSize="small" />
            </Button>
            <Button
              className="delete"
              aria-label="reset"
              onClick={handleDelete}
            >
              <DeleteForeverIcon fontSize="small" />
            </Button>
          </>
        )}
      </div>
      <img src={image?.image} />
    </div>
  );
};
