import * as React from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import BoulderImage from "../images/boulder.png";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/ArrowLeft";
import AddIcon from "@mui/icons-material/ArrowRight";
import { GameIdContext } from "../App";

export const HomeBuildingBoulder = (props) => {
  const [levelMargin, setLevelMargin] = React.useState(0);

  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";
  const buildingInfo =
    props.gameState?.playerData?.[props.player]?.home?.[props.buildingType];
  const boulderLevel = buildingInfo?.[`boulder${props.boulderID}`];
  const otherBoulderID = props.boulderID == 1 ? "2" : "1";
  const otherBoulderLevel = buildingInfo?.[`boulder${otherBoulderID}`];
  const setHouseMargin = () => {
    if (boulderLevel === 0) {
      if (levelMargin !== 0) {
        setLevelMargin(0);
      }
    } else if (boulderLevel === 1) {
      if (levelMargin !== 39) {
        setLevelMargin(39);
      }
    } else if (boulderLevel === 2) {
      if (levelMargin !== 77) {
        setLevelMargin(77);
      }
    } else if (boulderLevel === 3) {
      if (levelMargin !== 115) {
        setLevelMargin(115);
      }
    } else if (boulderLevel === 4) {
      if (levelMargin !== 154) {
        setLevelMargin(154);
      }
    } else if (boulderLevel === 5) {
      if (levelMargin !== 190) {
        setLevelMargin(190);
      }
    } else if (boulderLevel === 6) {
      if (levelMargin !== 230) {
        setLevelMargin(230);
      }
    } else if (boulderLevel === 7) {
      if (levelMargin !== 269) {
        setLevelMargin(269);
      }
    } else if (boulderLevel === 8) {
      if (levelMargin !== 308) {
        setLevelMargin(308);
      }
    } else if (boulderLevel === 9) {
      if (levelMargin !== 345) {
        setLevelMargin(345);
      }
    } else if (boulderLevel === 10) {
      if (levelMargin !== 380) {
        setLevelMargin(380);
      }
    } else if (boulderLevel === 11) {
      if (levelMargin !== 420) {
        setLevelMargin(420);
      }
    }
  };
  setHouseMargin();

  const handleUpgrade = () => {
    if (
      boulderLevel < 11 &&
      (props.boulderID === "1" ? otherBoulderLevel > boulderLevel + 1 : true)
    ) {
      let newAmount = boulderLevel + 1;
      let playerData = {
        [props.player]: {
          home: {
            [props.buildingType]: {
              [`boulder${props.boulderID}`]: newAmount,
            },
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };
  const handleDowngrade = () => {
    if (
      boulderLevel > 0 &&
      buildingInfo.level < boulderLevel &&
      (props.boulderID === "2" ? otherBoulderLevel < boulderLevel - 1 : true)
    ) {
      let newAmount = boulderLevel - 1;
      let playerData = {
        [props.player]: {
          home: {
            [props.buildingType]: {
              [`boulder${props.boulderID}`]: newAmount,
            },
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  return (
    <>
      <div className="boulder" style={{ marginLeft: levelMargin + "px" }}>
        <div className="controls">
          <ButtonGroup className="modifyButtonGroup">
            <Button aria-label="reduce" onClick={handleDowngrade}>
              <RemoveIcon fontSize="small" />
            </Button>
            <Button aria-label="increase" onClick={handleUpgrade}>
              <AddIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </div>
        <img className="boulderImage" src={BoulderImage} />
      </div>
    </>
  );
};
