import * as React from "react";
import Stack from "@mui/material/Stack";
import { InventoryItem } from "./InventoryItem";
import Rooster from "../images/rooster.png";

export const Inventory = (props) => {
  const playerInv = props.gameState?.playerData?.[props.player]?.inventory;

  return (
    <Stack className="inventory" spacing={2} direction="column">
      <div className="crops">
        <InventoryItem
          player={props.player}
          type="barley"
          amount={playerInv?.barley}
        />
        <InventoryItem
          player={props.player}
          type="flax"
          amount={playerInv?.flax}
        />
        <InventoryItem
          player={props.player}
          type="wheat"
          amount={playerInv?.wheat}
        />
        <InventoryItem
          player={props.player}
          type="hops"
          amount={playerInv?.hops}
        />
      </div>
      <div className="animals">
        <InventoryItem
          player={props.player}
          type="milk"
          amount={playerInv?.milk}
        />
        <InventoryItem
          player={props.player}
          type="meat"
          amount={playerInv?.meat}
        />
        <InventoryItem
          player={props.player}
          type="wool"
          amount={playerInv?.wool}
        />
        <InventoryItem
          player={props.player}
          type="fur"
          amount={playerInv?.fur}
        />
      </div>
      <div className="building">
        <InventoryItem
          player={props.player}
          type="brick"
          amount={playerInv?.brick}
        />
      </div>
      <div className="other">
        <InventoryItem
          player={props.player}
          type="jewels"
          amount={playerInv?.jewels}
        />
        <InventoryItem
          player={props.player}
          type="tools"
          amount={playerInv?.tools}
        />
        <div className="rooster">
          <img src={Rooster} />
          <div className="name">
            {props.gameState?.playerData?.[props.gameState?.rooster]?.name}
          </div>
        </div>
      </div>
    </Stack>
  );
};
