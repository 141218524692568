import { db } from "../firebase/firebase";
import { doc, addDoc, setDoc, collection } from "firebase/firestore";
import { gatewayBeginner } from "../cards/gateway-beginner";
import { gatewayAdvanced } from "../cards/gateway-advanced";
import { gatewayExpert } from "../cards/gateway-expert";
import { gatewayMaster } from "../cards/gateway-master";
import { farmyardHops } from "../cards/farmyardHops";
import { farmyardSheep } from "../cards/farmyardSheep";
import { farmyardField } from "../cards/farmyardField";
import { farmyardJewerly } from "../cards/farmyardJewerly";
import { market } from "../cards/market";
import { points } from "../cards/points";
import { fewerPlayers } from "../cards/fewerPlayers";
import { moveCards, shuffle } from "./helpers";
import _ from "lodash";
export const newGameStructureTemplate = {
  name: "dev",
  numberOfPlayers: 0,
  deckGateway: [],
  deckFarmyard: [],
  deckMarket: [],
  deckMission: [],
  fewerPlayersIndex: 0,
  rooster: "",
  board: {
    sheep_breeding: {
      occupied: 0,
    },
    sheep_shearing: {
      occupied: 0,
    },
    butchery: {
      occupied: 0,
    },
    nursery: {
      occupied: 0,
    },
    clay_delivery: {
      occupied: 0,
    },
    farming: {
      occupied: 0,
    },
    busy_weekend: {
      occupied: 0,
    },
    clearing: {
      occupied: 0,
    },
    small_trade: {
      occupied: 0,
    },
    cultivation: {
      occupied: 0,
    },
    fertilizing: {
      occupied: 0,
    },
    tool_shed: {
      occupied: 0,
    },
    weekly_market: {
      occupied: 0,
    },
    land_sale: {
      occupied: 0,
    },
    town_hall: {
      occupied: 0,
    },
    sheep_market: {
      occupied: 0,
    },
  },
  boardCards: {
    gateway: {
      occupied: 0,
    },
    farmyard: {
      occupied: 0,
    },
    market: {
      occupied: 0,
    },
    mission: {
      occupied: 0,
    },
  },
  playerData: {
    1: {
      home: {
        window: 6,
        carpentry: { level: 0, boulder1: 1, boulder2: 3 },
        brewhouse: { level: 0, boulder1: 1, boulder2: 3 },
        coolinghouse: { level: 0, boulder1: 1, boulder2: 3 },
        bakehouse: { level: 0, boulder1: 1, boulder2: 3 },
        manufacture: { level: 0, boulder1: 1, boulder2: 3 },
        card: false,
      },
      cards: {
        unplayed: [],
        gateway: [],
        farmyard: [],
        market: [],
        mission: [],
      },
      workers: {
        current: 6,
      },
      farm: {
        plot1: {
          level: 2,
          crop: "",
        },
        plot2: {
          level: 3,
          crop: "",
        },
        plot3: {
          level: 4,
          crop: "",
        },
        plot4: {
          level: 1,
          crop: "",
        },
        plot5: {
          level: 1,
          crop: "",
        },
        plot6: {
          level: 1,
          crop: "",
        },
        plot7: {
          level: 1,
          crop: "",
        },
        plot8: {
          level: 1,
          crop: "",
        },
      },
      inventory: {
        tools: 0,
        wheat: 1,
        flax: 1,
        barley: 1,
        hops: 0,
        milk: 0,
        meat: 0,
        wool: 0,
        brick: 0,
        fur: 0,
        jewels: 0,
      },
      sheep: {
        1: { card: true, sheep: 0 },
        2: { card: true, sheep: 0 },
        3: { card: true, sheep: 0 },
        4: { card: true, sheep: 0 },
        5: { card: true, sheep: 0 },
        6: { card: true, sheep: 0 },
        7: { sheep: 0 },
      },
    },
    2: {
      home: {
        window: 6,
        carpentry: { level: 0, boulder1: 1, boulder2: 3 },
        brewhouse: { level: 0, boulder1: 1, boulder2: 3 },
        coolinghouse: { level: 0, boulder1: 1, boulder2: 3 },
        bakehouse: { level: 0, boulder1: 1, boulder2: 3 },
        manufacture: { level: 0, boulder1: 1, boulder2: 3 },
        card: false,
      },
      cards: {
        unplayed: [],
        gateway: [],
        farmyard: [],
        market: [],
        mission: [],
      },
      workers: {
        current: 6,
      },
      farm: {
        plot1: {
          level: 2,
          crop: "",
        },
        plot2: {
          level: 3,
          crop: "",
        },
        plot3: {
          level: 4,
          crop: "",
        },
        plot4: {
          level: 1,
          crop: "",
        },
        plot5: {
          level: 1,
          crop: "",
        },
        plot6: {
          level: 1,
          crop: "",
        },
        plot7: {
          level: 1,
          crop: "",
        },
        plot8: {
          level: 1,
          crop: "",
        },
      },
      inventory: {
        tools: 0,
        wheat: 1,
        flax: 1,
        barley: 1,
        hops: 0,
        milk: 0,
        meat: 0,
        wool: 0,
        brick: 0,
        fur: 0,
        jewels: 0,
      },
      sheep: {
        1: { card: true, sheep: 0 },
        2: { card: true, sheep: 0 },
        3: { card: true, sheep: 0 },
        4: { card: true, sheep: 0 },
        5: { card: true, sheep: 0 },
        6: { card: true, sheep: 0 },
        7: { sheep: 0 },
      },
    },
    3: {
      home: {
        window: 6,
        carpentry: { level: 0, boulder1: 1, boulder2: 3 },
        brewhouse: { level: 0, boulder1: 1, boulder2: 3 },
        coolinghouse: { level: 0, boulder1: 1, boulder2: 3 },
        bakehouse: { level: 0, boulder1: 1, boulder2: 3 },
        manufacture: { level: 0, boulder1: 1, boulder2: 3 },
        card: false,
      },
      cards: {
        unplayed: [],
        gateway: [],
        farmyard: [],
        market: [],
        mission: [],
      },
      workers: {
        current: 6,
      },
      farm: {
        plot1: {
          level: 2,
          crop: "",
        },
        plot2: {
          level: 3,
          crop: "",
        },
        plot3: {
          level: 4,
          crop: "",
        },
        plot4: {
          level: 1,
          crop: "",
        },
        plot5: {
          level: 1,
          crop: "",
        },
        plot6: {
          level: 1,
          crop: "",
        },
        plot7: {
          level: 1,
          crop: "",
        },
        plot8: {
          level: 1,
          crop: "",
        },
      },
      inventory: {
        tools: 0,
        wheat: 1,
        flax: 1,
        barley: 1,
        hops: 0,
        milk: 0,
        meat: 0,
        wool: 0,
        brick: 0,
        fur: 0,
        jewels: 0,
      },
      sheep: {
        1: { card: true, sheep: 0 },
        2: { card: true, sheep: 0 },
        3: { card: true, sheep: 0 },
        4: { card: true, sheep: 0 },
        5: { card: true, sheep: 0 },
        6: { card: true, sheep: 0 },
        7: { sheep: 0 },
      },
    },
    4: {
      home: {
        window: 6,
        carpentry: { level: 0, boulder1: 1, boulder2: 3 },
        brewhouse: { level: 0, boulder1: 1, boulder2: 3 },
        coolinghouse: { level: 0, boulder1: 1, boulder2: 3 },
        bakehouse: { level: 0, boulder1: 1, boulder2: 3 },
        manufacture: { level: 0, boulder1: 1, boulder2: 3 },
        card: false,
      },
      cards: {
        unplayed: [],
        gateway: [],
        farmyard: [],
        market: [],
        mission: [],
      },
      workers: {
        current: 6,
      },
      farm: {
        plot1: {
          level: 2,
          crop: "",
        },
        plot2: {
          level: 3,
          crop: "",
        },
        plot3: {
          level: 4,
          crop: "",
        },
        plot4: {
          level: 1,
          crop: "",
        },
        plot5: {
          level: 1,
          crop: "",
        },
        plot6: {
          level: 1,
          crop: "",
        },
        plot7: {
          level: 1,
          crop: "",
        },
        plot8: {
          level: 1,
          crop: "",
        },
      },
      inventory: {
        tools: 0,
        wheat: 1,
        flax: 1,
        barley: 1,
        hops: 0,
        milk: 0,
        meat: 0,
        wool: 0,
        brick: 0,
        fur: 0,
        jewels: 0,
      },
      sheep: {
        1: { card: true, sheep: 0 },
        2: { card: true, sheep: 0 },
        3: { card: true, sheep: 0 },
        4: { card: true, sheep: 0 },
        5: { card: true, sheep: 0 },
        6: { card: true, sheep: 0 },
        7: { sheep: 0 },
      },
    },
  },
};

export const newGameSetup = (
  gameName,
  playerNames,
  gateway,
  farmyard,
  setGameId
) => {
  let newGameStructure = _.cloneDeep(newGameStructureTemplate);
  const players = playerNames.length;
  newGameStructure.name = gameName;
  newGameStructure.numberOfPlayers = players;

  playerNames.forEach((name, index) => {
    newGameStructure.playerData[index + 1].name = name;
  });

  let gatewayIds;
  let gatewayType;
  if (gateway === "Beginner") {
    gatewayIds = Object.keys(gatewayBeginner);
    gatewayType = "beginner";
  } else if (gateway === "Advanced") {
    gatewayIds = Object.keys(gatewayAdvanced);
    gatewayType = "advanced";
  } else if (gateway === "Expert") {
    gatewayIds = Object.keys(gatewayExpert);
    gatewayType = "expert";
  } else if (gateway === "Master") {
    gatewayIds = Object.keys(gatewayMaster);
    gatewayType = "master";
  }
  shuffle(gatewayIds);
  newGameStructure.deckGateway = gatewayIds;

  let farmyardIds;
  let farmyardType;
  if (farmyard === "Hops") {
    farmyardIds = Object.keys(farmyardHops);
    farmyardType = "hops";
  } else if (farmyard === "Sheep") {
    farmyardIds = Object.keys(farmyardSheep);
    farmyardType = "sheep";
  } else if (farmyard === "Field") {
    farmyardIds = Object.keys(farmyardField);
    farmyardType = "field";
  } else if (farmyard === "Jewelry") {
    farmyardIds = Object.keys(farmyardJewerly);
    farmyardType = "jewelry";
  }

  console.log(farmyard);

  shuffle(farmyardIds);

  newGameStructure.deckGateway = gatewayIds;
  newGameStructure.deckFarmyard = farmyardIds;
  newGameStructure.gatewayType = gatewayType;
  newGameStructure.farmyardType = farmyardType;

  newGameStructure.deckFewerPlayers = shuffle(Object.keys(fewerPlayers));

  // points and market cards
  newGameStructure.deckMarket = shuffle(Object.keys(market));
  newGameStructure.deckMission = shuffle(Object.keys(points));

  // distribute 6 gateway cards and 1 point card to each player
  for (var t = 0; t < players; t++) {
    moveCards(
      newGameStructure.deckGateway,
      newGameStructure.playerData[t + 1].cards.unplayed,
      4
    );
    moveCards(
      newGameStructure.deckMission,
      newGameStructure.playerData[t + 1].cards.unplayed,
      1
    );
  }

  // for less than 4 players, add 1 row of occupy on everything
  if (players < 4) {
    let boardSpaces = Object.keys(newGameStructure.board);
    boardSpaces.forEach((space) => {
      newGameStructure.board[space].occupied = 1;
    });
    let boardCardSpaces = Object.keys(newGameStructure.boardCards);
    boardCardSpaces.forEach((space) => {
      newGameStructure.boardCards[space].occupied = 1;
    });
  }

  // set rooster
  newGameStructure.rooster = 1;
  newGameStructure.date = new Date();

  const gameRef = collection(db, "games");
  addDoc(gameRef, newGameStructure).then((docRef) => {
    setGameId(docRef.id);
  });
};
