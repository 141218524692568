export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const moveCards = (fromArray, toArray, numberOfCards) => {
  for (var i = 0; i < numberOfCards; i++) {
    toArray.push(fromArray[i]);
  }
  fromArray.splice(0, numberOfCards);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sortCardsIntoCategory = (cardId) => {
  if (cardId < 400) {
    return "gateway";
  } else if (cardId < 800) {
    return "farmyard";
  } else if (cardId < 900) {
    return "market";
  } else if (cardId < 1000) {
    return "mission";
  }
};
