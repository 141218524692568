import * as React from "react";
import { Worker } from "./Worker";
import RemoveWorkerIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { GameIdContext } from "../App";

export const ActionSquare = (props) => {
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";

  const squareLevel = props.gameState?.board?.[props.actionName]?.occupied;
  const playerWorkers =
    props.gameState?.playerData?.[props.player]?.workers?.current;

  const handleUseSpot = () => {
    let newAmount = squareLevel + 1;
    let newWorkers = playerWorkers - newAmount;
    if (squareLevel < 3 && newWorkers >= 0) {
      let boardData = {
        board: {
          [props.actionName]: {
            occupied: newAmount,
          },
        },
        playerData: {
          [props.player]: {
            workers: {
              current: newWorkers,
            },
          },
        },
      };
      setDoc(gameRef, boardData, { merge: true });
    }
  };

  const handleRemoveRow = () => {
    if (squareLevel > 0) {
      let newAmount = squareLevel - 1;
      let boardData = {
        board: {
          [props.actionName]: {
            occupied: newAmount,
          },
        },
      };
      setDoc(gameRef, boardData, { merge: true });
    }
  };

  return (
    <div className={`actionSquare ${props.actionName}`}>
      <div className="controls">
        <Button className="activate" aria-label="reset" onClick={handleUseSpot}>
          <AutoFixNormalIcon fontSize="small" />
        </Button>
        <Button
          className="remove"
          aria-label="remove"
          onClick={handleRemoveRow}
        >
          <RemoveWorkerIcon fontSize="small" />
        </Button>
      </div>
      {squareLevel > 2 && (
        <div className="row-three">
          <Worker />
          <Worker />
          <Worker />
        </div>
      )}
      {squareLevel > 1 && (
        <div className="row-two">
          <Worker />
          <Worker />
        </div>
      )}
      {squareLevel > 0 && (
        <div className="row-one">
          <Worker />
        </div>
      )}
    </div>
  );
};
