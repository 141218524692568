import c201 from "../images/cards/201.png";
import c202 from "../images/cards/202.png";
import c203 from "../images/cards/203.png";
import c204 from "../images/cards/204.png";
import c205 from "../images/cards/205.png";
import c206 from "../images/cards/206.png";
import c207 from "../images/cards/207.png";
import c208 from "../images/cards/208.png";
import c209 from "../images/cards/209.png";
import c210 from "../images/cards/210.png";
import c211 from "../images/cards/211.png";
import c212 from "../images/cards/212.png";
import c213 from "../images/cards/213.png";
import c214 from "../images/cards/214.png";
import c215 from "../images/cards/215.png";
import c216 from "../images/cards/216.png";
import c217 from "../images/cards/217.png";
import c218 from "../images/cards/218.png";
import c219 from "../images/cards/219.png";
import c220 from "../images/cards/220.png";
import c221 from "../images/cards/221.png";
import c222 from "../images/cards/222.png";
import c223 from "../images/cards/223.png";
import c224 from "../images/cards/224.png";
import c225 from "../images/cards/225.png";
import c226 from "../images/cards/226.png";
import c227 from "../images/cards/227.png";
import c228 from "../images/cards/228.png";
import c229 from "../images/cards/229.png";
import c230 from "../images/cards/230.png";

export const gatewayExpert = {
  201: { image: c201 },
  202: { image: c202 },
  203: { image: c203 },
  204: { image: c204 },
  205: { image: c205 },
  206: { image: c206 },
  207: { image: c207 },
  208: { image: c208 },
  209: { image: c209 },
  210: { image: c210 },
  211: { image: c211 },
  212: { image: c212 },
  213: { image: c213 },
  214: { image: c214 },
  215: { image: c215 },
  216: { image: c216 },
  217: { image: c217 },
  218: { image: c218 },
  219: { image: c219 },
  220: { image: c220 },
  221: { image: c221 },
  222: { image: c222 },
  223: { image: c223 },
  224: { image: c224 },
  225: { image: c225 },
  226: { image: c226 },
  227: { image: c227 },
  228: { image: c228 },
  229: { image: c229 },
  230: { image: c230 },
};
