import * as React from "react";
//import {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { newGameSetup } from "../utils/gameSetup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { capitalizeFirstLetter } from "../utils/helpers";
import { LoadTable } from "./LoadTable";

export const MenuButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadModalOpen, setLoadModalOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [gateway, setGateway] = React.useState("Beginner");
  const [farmyard, setFarmyard] = React.useState("Hops");
  const [gameName, setGameName] = React.useState("");
  const [player1Name, setPlayer1Name] = React.useState("");
  const [player2Name, setPlayer2Name] = React.useState("");
  const [player3Name, setPlayer3Name] = React.useState("");
  const [player4Name, setPlayer4Name] = React.useState("");

  React.useEffect(() => {
    if (props.gameState?.name) {
      setGameName(props.gameState?.name);
    }
    if (props.gameState?.gatewayType) {
      setGateway(capitalizeFirstLetter(props.gameState?.gatewayType));
    }
    if (props.gameState?.farmyardType) {
      setFarmyard(capitalizeFirstLetter(props.gameState?.farmyardType));
    }
    if (props.gameState?.playerData?.[1]?.name) {
      //setPlayer1Name(props.gameState?.playerData?.[1]?.name);
    }
    if (props.gameState?.playerData?.[2]?.name) {
      //setPlayer2Name(props.gameState?.playerData?.[2]?.name);
    }
    if (props.gameState?.playerData?.[3]?.name) {
      //setPlayer3Name(props.gameState?.playerData?.[3]?.name);
    }
    if (props.gameState?.playerData?.[4]?.name) {
      //setPlayer4Name(props.gameState?.playerData?.[4]?.name);
    }
  }, [props]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleUndo = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = () => {
    setAnchorEl(null);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleLoadModalOpen = () => {
    setAnchorEl(null);
    setLoadModalOpen(true);
  };

  const handleLoadModalClose = () => {
    setLoadModalOpen(false);
  };

  const handleGatewayChange = (event) => {
    setGateway(event.target.value);
  };

  const handleFarmyardChange = (event) => {
    setFarmyard(event.target.value);
  };

  const handleGameNameChange = (event) => {
    setGameName(event.target.value);
  };

  const handlePlayer1NameChange = (event) => {
    setPlayer1Name(event.target.value);
  };

  const handlePlayer2NameChange = (event) => {
    setPlayer2Name(event.target.value);
  };

  const handlePlayer3NameChange = (event) => {
    setPlayer3Name(event.target.value);
  };

  const handlePlayer4NameChange = (event) => {
    setPlayer4Name(event.target.value);
  };

  const handleStartGame = () => {
    let playerNames = [];
    if (player1Name) {
      playerNames.push(player1Name);
    }
    if (player2Name) {
      playerNames.push(player2Name);
    }
    if (player3Name) {
      playerNames.push(player3Name);
    }
    if (player4Name) {
      playerNames.push(player4Name);
    }
    newGameSetup(gameName, playerNames, gateway, farmyard, props.setGameId);
    setModalOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    bgcolor: "#fff",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={loadModalOpen}
        onClose={handleLoadModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="loadGameModal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Load Room
          </Typography>
          <LoadTable
            setGameId={props.setGameId}
            closeModal={handleLoadModalClose}
          />
        </Box>
      </Modal>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="newGameModal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            New Room
          </Typography>
          <div className="newGameForm">
            <div className="gameName">
              <FormControl>
                <TextField
                  className="gameName"
                  id="gameName"
                  label="Game name"
                  variant="filled"
                  helperText="Can be replayed with the same players"
                  required
                  onChange={handleGameNameChange}
                  value={gameName}
                />
              </FormControl>
            </div>
            <div className="players">
              <FormControl>
                <TextField
                  className="playerInput"
                  id="player1"
                  label="Player 1"
                  variant="filled"
                  required
                  onChange={handlePlayer1NameChange}
                  value={player1Name}
                />
              </FormControl>
              <FormControl>
                <TextField
                  className="playerInput"
                  id="player2"
                  label="Player 2"
                  variant="filled"
                  onChange={handlePlayer2NameChange}
                  value={player2Name}
                />
              </FormControl>
              <FormControl>
                <TextField
                  className="playerInput"
                  id="player3"
                  label="Player 3"
                  variant="filled"
                  onChange={handlePlayer3NameChange}
                  value={player3Name}
                />
              </FormControl>
              <FormControl>
                <TextField
                  className="playerInput"
                  id="player4"
                  label="Player 4"
                  variant="filled"
                  onChange={handlePlayer4NameChange}
                  value={player4Name}
                />
              </FormControl>
            </div>
            <div className="gameType">
              <div className="gateway">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="gateway-select-label"
                    id="gateway-select"
                    value={gateway}
                    helperText="Gateway Deck"
                    onChange={handleGatewayChange}
                  >
                    <MenuItem value={"Beginner"}>Beginner</MenuItem>
                    <MenuItem value={"Advanced"}>Advanced</MenuItem>
                    <MenuItem value={"Expert"}>Expert</MenuItem>
                    <MenuItem value={"Master"}>Master</MenuItem>
                  </Select>
                  <FormHelperText>Gateway Deck</FormHelperText>
                </FormControl>
              </div>
              <div className="farmyard">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="farmyard-select-label"
                    id="farmyard-select"
                    value={farmyard}
                    helperText="Farmyard Deck"
                    onChange={handleFarmyardChange}
                  >
                    <MenuItem value={"Hops"}>Hops</MenuItem>
                    <MenuItem value={"Field"}>Field</MenuItem>
                    <MenuItem value={"Sheep"}>Sheep</MenuItem>
                    <MenuItem value={"Jewelry"}>Jewelry</MenuItem>
                  </Select>
                  <FormHelperText>Farmyard Deck</FormHelperText>
                </FormControl>
              </div>
              <Button variant="contained" onClick={handleStartGame}>
                Start Game
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Menu
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/*<MenuItem onClick={handleUndo}>Undo</MenuItem>*/}
        <MenuItem onClick={handleModalOpen}>New Room</MenuItem>
        <MenuItem onClick={handleLoadModalOpen}>Load Room</MenuItem>
      </Menu>
    </>
  );
};
