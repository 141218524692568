import c301 from "../images/cards/301.png";
import c302 from "../images/cards/302.png";
import c303 from "../images/cards/303.png";
import c304 from "../images/cards/304.png";
import c305 from "../images/cards/305.png";
import c306 from "../images/cards/306.png";
import c307 from "../images/cards/307.png";
import c308 from "../images/cards/308.png";
import c309 from "../images/cards/309.png";
import c310 from "../images/cards/310.png";
import c311 from "../images/cards/311.png";
import c312 from "../images/cards/312.png";
import c313 from "../images/cards/313.png";
import c314 from "../images/cards/314.png";
import c315 from "../images/cards/315.png";
import c316 from "../images/cards/316.png";
import c317 from "../images/cards/317.png";
import c318 from "../images/cards/318.png";
import c319 from "../images/cards/319.png";
import c320 from "../images/cards/320.png";
import c321 from "../images/cards/321.png";
import c322 from "../images/cards/322.png";
import c323 from "../images/cards/323.png";
import c324 from "../images/cards/324.png";
import c325 from "../images/cards/325.png";
import c326 from "../images/cards/326.png";
import c327 from "../images/cards/327.png";
import c328 from "../images/cards/328.png";
import c329 from "../images/cards/329.png";
import c330 from "../images/cards/330.png";

export const gatewayMaster = {
  301: { image: c301 },
  302: { image: c302 },
  303: { image: c303 },
  304: { image: c304 },
  305: { image: c305 },
  306: { image: c306 },
  307: { image: c307 },
  308: { image: c308 },
  309: { image: c309 },
  310: { image: c310 },
  311: { image: c311 },
  312: { image: c312 },
  313: { image: c313 },
  314: { image: c314 },
  315: { image: c315 },
  316: { image: c316 },
  317: { image: c317 },
  318: { image: c318 },
  319: { image: c319 },
  320: { image: c320 },
  321: { image: c321 },
  322: { image: c322 },
  323: { image: c323 },
  324: { image: c324 },
  325: { image: c325 },
  326: { image: c326 },
  327: { image: c327 },
  328: { image: c328 },
  329: { image: c329 },
  330: { image: c330 },
};
