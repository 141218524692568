import * as React from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import BuildingBakehouseImage from "../images/home-bakehouse.png";
import BuildingBrewhouseImage from "../images/home-brewhouse.png";
import BuildingCarpentryImage from "../images/home-carpentry.png";
import BuildingCoolinghouseImage from "../images/home-coolinghouse.png";
import BuildingManufactureImage from "../images/home-manufacture.png";

import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/ArrowLeft";
import AddIcon from "@mui/icons-material/ArrowRight";
import { GameIdContext } from "../App";

export const HomeBuilding = (props) => {
  const [levelMargin, setLevelMargin] = React.useState(0);

  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";
  const buildingInfo =
    props.gameState?.playerData?.[props.player]?.home?.[props.buildingType];
  const homeLevel = props.gameState?.playerData?.[props.player]?.home?.window;
  const level = buildingInfo?.level;
  const setHouseMargin = () => {
    if (level === 0) {
      if (levelMargin !== 0) {
        setLevelMargin(0);
      }
    } else if (level === 1) {
      if (levelMargin !== 39) {
        setLevelMargin(39);
      }
    } else if (level === 2) {
      if (levelMargin !== 77) {
        setLevelMargin(77);
      }
    } else if (level === 3) {
      if (levelMargin !== 115) {
        setLevelMargin(115);
      }
    } else if (level === 4) {
      if (levelMargin !== 154) {
        setLevelMargin(154);
      }
    } else if (level === 5) {
      if (levelMargin !== 190) {
        setLevelMargin(190);
      }
    } else if (level === 6) {
      if (levelMargin !== 230) {
        setLevelMargin(230);
      }
    } else if (level === 7) {
      if (levelMargin !== 269) {
        setLevelMargin(269);
      }
    } else if (level === 8) {
      if (levelMargin !== 308) {
        setLevelMargin(308);
      }
    } else if (level === 9) {
      if (levelMargin !== 345) {
        setLevelMargin(345);
      }
    } else if (level === 10) {
      if (levelMargin !== 380) {
        setLevelMargin(380);
      }
    }
  };
  setHouseMargin();
  let buildingImage;
  const pickBuildingImage = () => {
    if (props.buildingType === "bakehouse") {
      buildingImage = BuildingBakehouseImage;
    } else if (props.buildingType === "carpentry") {
      buildingImage = BuildingCarpentryImage;
    } else if (props.buildingType === "brewhouse") {
      buildingImage = BuildingBrewhouseImage;
    } else if (props.buildingType === "coolinghouse") {
      buildingImage = BuildingCoolinghouseImage;
    } else if (props.buildingType === "manufacture") {
      buildingImage = BuildingManufactureImage;
    }
  };
  pickBuildingImage();
  const handleUpgrade = () => {
    if (level < 16 && level < buildingInfo?.boulder1) {
      let newAmount = level + 1;
      let playerData = {
        [props.player]: {
          home: {
            [props.buildingType]: {
              level: newAmount,
            },
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };
  const handleDowngrade = () => {
    if (homeLevel - level < 6) {
      let newAmount = level - 1;
      let playerData = {
        [props.player]: {
          home: {
            [props.buildingType]: {
              level: newAmount,
            },
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  return (
    <div className="building" style={{ marginLeft: levelMargin + "px" }}>
      <div className="controls">
        <ButtonGroup className="modifyButtonGroup">
          <Button aria-label="reduce" onClick={handleDowngrade}>
            <RemoveIcon fontSize="small" />
          </Button>
          <Button aria-label="increase" onClick={handleUpgrade}>
            <AddIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      </div>
      <img className="buildingImage" src={buildingImage} />
    </div>
  );
};
