import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export const PlayerButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [textPlayer, setTextPlayer] = React.useState("");
  const open = Boolean(anchorEl);

  const numberOfPlayers = props.gameState?.numberOfPlayers;

  let playersInfo = [];
  for (var i = 1; i < numberOfPlayers + 1; i++) {
    playersInfo.push(props.gameState?.playerData?.[i]?.name);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePlayer = (id) => {
    props.setPlayer(id.toString());
    setTextPlayer(playersInfo[id - 1]);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setTextPlayer(playersInfo[0]);
  }, [props.gameState?.name]);

  return (
    <>
      {textPlayer && (
        <>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {textPlayer}
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {playersInfo.map((playerName, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handlePlayer(index + 1);
                  }}
                >
                  {playerName}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </>
  );
};
