import * as React from "react";
import Badge from "@mui/material/Badge";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import shortid from "shortid";

import InvBarley from "../images/inv-barley.png";
import InvFlax from "../images/inv-flax.png";
import InvWheat from "../images/inv-wheat.png";
import InvHops from "../images/inv-hops.png";
import InvMilk from "../images/inv-milk.png";
import InvMeat from "../images/inv-meat.png";
import InvFur from "../images/inv-fur.png";
import InvWool from "../images/inv-wool.png";
import InvBrick from "../images/inv-brick.png";
import InvJewel from "../images/inv-jewel.png";
import InvTools from "../images/inv-tools.png";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { GameIdContext } from "../App";

export const InventoryItem = (props) => {
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";
  const [uniqueId, setUniqueId] = React.useState(shortid.generate());
  let properties = {};

  switch (props.type) {
    case "barley":
      properties.src = InvBarley;
      break;
    case "flax":
      properties.src = InvFlax;
      break;
    case "wheat":
      properties.src = InvWheat;
      break;
    case "hops":
      properties.src = InvHops;
      break;
    case "milk":
      properties.src = InvMilk;
      break;
    case "meat":
      properties.src = InvMeat;
      break;
    case "fur":
      properties.src = InvFur;
      break;
    case "wool":
      properties.src = InvWool;
      break;
    case "brick":
      properties.src = InvBrick;
      break;
    case "jewels":
      properties.src = InvJewel;
      break;
    case "tools":
      properties.src = InvTools;
      break;
    default:
      break;
  }

  const handleAdd = () => {
    let newAmount = props.amount + 1;

    let playerData = {
      [props.player]: {
        inventory: {
          [props.type]: newAmount,
        },
      },
    };
    setDoc(gameRef, { playerData }, { merge: true });
  };

  const handleRemove = () => {
    if (props.amount > 0) {
      let newAmount = props.amount - 1;
      let playerData = {
        [props.player]: {
          inventory: {
            [props.type]: newAmount,
          },
        },
      };
      setDoc(gameRef, { playerData }, { merge: true });
    }
  };

  React.useEffect(() => {
    setUniqueId(shortid.generate());
  }, [props.amount]);

  return (
    <div key={uniqueId} className="inventoryItem">
      <Badge
        badgeContent={props.amount}
        className={props.type}
        color="primary"
        showZero
      >
        <img className="invIcon hithere" src={properties.src} />
      </Badge>
      <ButtonGroup className={`modifyButtonGroup ${props.type}`}>
        <Button aria-label="reduce" onClick={handleRemove}>
          <RemoveIcon fontSize="small" />
        </Button>
        <Button aria-label="increase" onClick={handleAdd}>
          <AddIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    </div>
  );
};
