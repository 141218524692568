import c501 from "../images/cards/501.png";
import c502 from "../images/cards/502.png";
import c503 from "../images/cards/503.png";
import c504 from "../images/cards/504.png";
import c505 from "../images/cards/505.png";
import c506 from "../images/cards/506.png";
import c507 from "../images/cards/507.png";
import c508 from "../images/cards/508.png";
import c509 from "../images/cards/509.png";
import c510 from "../images/cards/510.png";
import c511 from "../images/cards/511.png";
import c512 from "../images/cards/512.png";
import c513 from "../images/cards/513.png";
import c514 from "../images/cards/514.png";
import c515 from "../images/cards/515.png";
import c516 from "../images/cards/516.png";
import c517 from "../images/cards/517.png";
import c518 from "../images/cards/518.png";
import c519 from "../images/cards/519.png";
import c520 from "../images/cards/520.png";
import c521 from "../images/cards/521.png";
import c522 from "../images/cards/522.png";
import c523 from "../images/cards/523.png";
import c524 from "../images/cards/524.png";
import c525 from "../images/cards/525.png";
import c526 from "../images/cards/526.png";
import c527 from "../images/cards/527.png";
import c528 from "../images/cards/528.png";
import c529 from "../images/cards/529.png";
import c530 from "../images/cards/530.png";
import c531 from "../images/cards/531.png";
import c532 from "../images/cards/532.png";
import c533 from "../images/cards/533.png";
import c534 from "../images/cards/534.png";
import c535 from "../images/cards/535.png";

export const farmyardSheep = {
  501: { image: c501 },
  502: { image: c502 },
  503: { image: c503 },
  504: { image: c504 },
  505: { image: c505 },
  506: { image: c506 },
  507: { image: c507 },
  508: { image: c508 },
  509: { image: c509 },
  510: { image: c510 },
  511: { image: c511 },
  512: { image: c512 },
  513: { image: c513 },
  514: { image: c514 },
  515: { image: c515 },
  516: { image: c516 },
  517: { image: c517 },
  518: { image: c518 },
  519: { image: c519 },
  520: { image: c520 },
  521: { image: c521 },
  522: { image: c522 },
  523: { image: c523 },
  524: { image: c524 },
  525: { image: c525 },
  526: { image: c526 },
  527: { image: c527 },
  528: { image: c528 },
  529: { image: c529 },
  530: { image: c530 },
  531: { image: c531 },
  532: { image: c532 },
  533: { image: c533 },
  534: { image: c534 },
  535: { image: c535 },
};
