import * as React from "react";
import HomeBoard from "../images/home-board.png";
import { HomeBuilding } from "./HomeBuilding";
import { HomeBuildingBoulder } from "./HomeBuildingBoulder";
import { HomeHouse } from "./HomeHouse";

export const Home = (props) => {
  return (
    <div className="home">
      <div className="buildings">
        <HomeBuilding
          gameState={props.gameState}
          player={props.player}
          buildingType="carpentry"
        />
        <HomeBuilding
          gameState={props.gameState}
          player={props.player}
          buildingType="brewhouse"
        />
        <HomeBuilding
          gameState={props.gameState}
          player={props.player}
          buildingType="coolinghouse"
        />
        <HomeBuilding
          gameState={props.gameState}
          player={props.player}
          buildingType="bakehouse"
        />
        <HomeBuilding
          gameState={props.gameState}
          player={props.player}
          buildingType="manufacture"
        />
      </div>
      <div className="boulders">
        <div className="boulderRow">
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="carpentry"
            boulderID="1"
          />
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="carpentry"
            boulderID="2"
          />
        </div>
        <div className="boulderRow">
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="brewhouse"
            boulderID="1"
          />
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="brewhouse"
            boulderID="2"
          />
        </div>
        <div className="boulderRow">
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="coolinghouse"
            boulderID="1"
          />
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="coolinghouse"
            boulderID="2"
          />
        </div>
        <div className="boulderRow">
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="bakehouse"
            boulderID="1"
          />
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="bakehouse"
            boulderID="2"
          />
        </div>
        <div className="boulderRow">
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="manufacture"
            boulderID="1"
          />
          <HomeBuildingBoulder
            gameState={props.gameState}
            player={props.player}
            buildingType="manufacture"
            boulderID="2"
          />
        </div>
      </div>
      <HomeHouse gameState={props.gameState} player={props.player} />
      <img className="board" src={HomeBoard} />
    </div>
  );
};
