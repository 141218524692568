import * as React from "react";
import WorkerImage from "../images/worker.png";
export const Worker = (props) => {
  const [rotate, setRotate] = React.useState(0);
  const [marginRight, setMarginRight] = React.useState(0);

  React.useEffect(() => {
    if (props.scatter) {
      const negativeRand = Math.round(Math.random()) ? 1 : -1;
      setRotate(Math.random() * 40 * negativeRand);
      setMarginRight(Math.floor(Math.random() * 20));
    }
  }, []);

  return (
    <div
      className="worker animate splat"
      style={{
        transform: `rotate(${rotate}deg)`,
        marginRight: marginRight + "px",
      }}
    >
      <img src={WorkerImage} />
    </div>
  );
};
