import * as React from "react";
import ActionBoardImage from "../images/action-board.png";
import LeatherBg from "../images/leather.jpg";
import { ActionSquare } from "./ActionSquare";
import { CardSquare } from "./CardSquare";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { CardRemoval } from "./CardRemoval";
import { GameIdContext } from "../App";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button } from "@mui/material";

export const ActionBoard = (props) => {
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";

  let rightSwipe = -1000;
  if (props.open) {
    rightSwipe = 0;
  }

  const numberOfPlayers = props.gameState?.numberOfPlayers;

  const cardOptions = ["gateway", "market", "farmyard", "mission"];

  const actionOptions = [
    "sheep_breeding",
    "clay_delivery",
    "small_trade",
    "weekly_market",
    "sheep_shearing",
    "farming",
    "cultivation",
    "land_sale",
    "busy_weekend",
    "butchery",
    "fertilizing",
    "town_hall",
    "nursery",
    "clearing",
    "tool_shed",
    "sheep_market",
  ];

  const q1Options = [
    "sheep_breeding",
    "clay_delivery",
    "sheep_shearing",
    "farming",
  ];

  const q2Options = ["butchery", "nursery", "busy_weekend", "clearing"];
  const q3Options = [
    "small_trade",
    "cultivation",
    "weekly_market",
    "land_sale",
  ];
  const q4Options = ["fertilizing", "tool_shed", "town_hall", "sheep_market"];

  const handleClearRound = () => {
    const boardInfo = props.gameState?.board;
    const boardCardsInfo = props.gameState?.boardCards;
    const actionSpaces = Object.keys(boardInfo);
    const cardSpaces = Object.keys(boardCardsInfo);
    let board = {};
    actionSpaces.forEach((actionName) => {
      let currentLevel = boardInfo?.[actionName]?.occupied;
      board[actionName] = {};
      board[actionName].occupied = currentLevel > 0 ? currentLevel - 1 : 0;
    });
    let boardCards = {};
    cardSpaces.forEach((cardName) => {
      let currentLevel = boardCardsInfo?.[cardName]?.occupied;
      boardCards[cardName] = {};
      boardCards[cardName].occupied = currentLevel > 0 ? currentLevel - 1 : 0;
    });
    setDoc(gameRef, { board, boardCards }, { merge: true });
  };

  const handleClearQ1 = () => {
    const boardInfo = props.gameState?.board;
    const boardCardsInfo = props.gameState?.boardCards;
    let board = {};
    q1Options.forEach((actionName) => {
      let currentLevel = boardInfo?.[actionName]?.occupied;
      board[actionName] = {};
      board[actionName].occupied = currentLevel > 0 ? currentLevel - 1 : 0;
    });
    let boardCards = {};
    let currentCardLevel = boardCardsInfo?.["gateway"]?.occupied;
    boardCards["gateway"] = {};
    boardCards["gateway"].occupied =
      currentCardLevel > 0 ? currentCardLevel - 1 : 0;

    let finalNewObject = {};
    if (Object.keys(board).length > 0) {
      finalNewObject.board = board;
    }
    if (Object.keys(boardCards).length > 0) {
      finalNewObject.boardCards = boardCards;
    }
    setDoc(gameRef, finalNewObject, { merge: true });
  };

  const handleClearQ2 = () => {
    const boardInfo = props.gameState?.board;
    const boardCardsInfo = props.gameState?.boardCards;
    let board = {};
    q2Options.forEach((actionName) => {
      let currentLevel = boardInfo?.[actionName]?.occupied;
      board[actionName] = {};
      board[actionName].occupied = currentLevel > 0 ? currentLevel - 1 : 0;
    });
    let boardCards = {};
    let currentCardLevel = boardCardsInfo?.["farmyard"]?.occupied;
    boardCards["farmyard"] = {};
    boardCards["farmyard"].occupied =
      currentCardLevel > 0 ? currentCardLevel - 1 : 0;

    let finalNewObject = {};
    if (Object.keys(board).length > 0) {
      finalNewObject.board = board;
    }
    if (Object.keys(boardCards).length > 0) {
      finalNewObject.boardCards = boardCards;
    }
    setDoc(gameRef, finalNewObject, { merge: true });
  };

  const handleClearQ3 = () => {
    const boardInfo = props.gameState?.board;
    const boardCardsInfo = props.gameState?.boardCards;
    let board = {};
    q3Options.forEach((actionName) => {
      let currentLevel = boardInfo?.[actionName]?.occupied;
      board[actionName] = {};
      board[actionName].occupied = currentLevel > 0 ? currentLevel - 1 : 0;
    });
    let boardCards = {};
    let currentCardLevel = boardCardsInfo?.["market"]?.occupied;
    boardCards["market"] = {};
    boardCards["market"].occupied =
      currentCardLevel > 0 ? currentCardLevel - 1 : 0;

    let finalNewObject = {};
    if (Object.keys(board).length > 0) {
      finalNewObject.board = board;
    }
    if (Object.keys(boardCards).length > 0) {
      finalNewObject.boardCards = boardCards;
    }
    setDoc(gameRef, finalNewObject, { merge: true });
  };

  const handleClearQ4 = () => {
    const boardInfo = props.gameState?.board;
    const boardCardsInfo = props.gameState?.boardCards;
    let board = {};
    q4Options.forEach((actionName) => {
      let currentLevel = boardInfo?.[actionName]?.occupied;
      board[actionName] = {};
      board[actionName].occupied = currentLevel > 0 ? currentLevel - 1 : 0;
    });
    let boardCards = {};
    let currentCardLevel = boardCardsInfo?.["mission"]?.occupied;
    boardCards["mission"] = {};
    boardCards["mission"].occupied =
      currentCardLevel > 0 ? currentCardLevel - 1 : 0;

    let finalNewObject = {};
    if (Object.keys(board).length > 0) {
      finalNewObject.board = board;
    }
    if (Object.keys(boardCards).length > 0) {
      finalNewObject.boardCards = boardCards;
    }
    console.log(finalNewObject);
    setDoc(gameRef, finalNewObject, { merge: true });
  };

  return (
    <div className="actionBoard" style={{ right: rightSwipe + "px" }}>
      {props.open && (
        <Button
          className="hideActionBoard"
          aria-label="reset"
          onClick={() => {
            props.setShowActionBoard(false);
          }}
        >
          <ChevronRightIcon fontSize="small" />
        </Button>
      )}
      {!props.open && (
        <Button
          className="showActionBoard"
          aria-label="reset"
          onClick={() => {
            props.setShowActionBoard(true);
          }}
        >
          <ChevronLeftIcon fontSize="small" />
        </Button>
      )}
      <div className="board">
        {actionOptions.map((name, index) => {
          return (
            <ActionSquare
              key={index}
              gameState={props.gameState}
              actionName={name}
              player={props.player}
            />
          );
        })}
        {cardOptions.map((name, index) => {
          return (
            <CardSquare
              key={index}
              gameState={props.gameState}
              actionName={name}
              player={props.player}
              setShowActionBoard={props.setShowActionBoard}
            />
          );
        })}
        <img className="actionBoardImage" src={ActionBoardImage} />
        {numberOfPlayers < 4 && (
          <div className="fewerOptions">
            <div className="fewerRemoval">
              <CardRemoval gameState={props.gameState} />
            </div>
            <div className="removeButtonsGroup">
              <button onClick={handleClearQ1}>Clear Q1</button>
              <button onClick={handleClearQ2}>Clear Q2</button>
              <button onClick={handleClearQ3}>Clear Q3</button>
              <button onClick={handleClearQ4}>Clear Q4</button>
            </div>
          </div>
        )}

        {numberOfPlayers === 4 && (
          <div className="clearRound">
            <button onClick={handleClearRound}>Clear Round</button>
          </div>
        )}
      </div>
      <img className="background" src={LeatherBg} />
    </div>
  );
};
