import * as React from "react";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { fewerPlayers } from "../cards/fewerPlayers";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { GameIdContext } from "../App";

export const CardRemoval = (props) => {
  const [image, setImage] = React.useState();
  const cardIndex = props.gameState?.fewerPlayersIndex;
  const gameId = React.useContext(GameIdContext);
  let gameRef = gameId ? doc(db, "games", gameId) : "";
  const fewerPlayersDeck = props.gameState?.deckFewerPlayers;
  if (image !== fewerPlayers?.[fewerPlayersDeck?.[cardIndex]]) {
    setImage(fewerPlayers?.[fewerPlayersDeck?.[cardIndex]]);
  }

  const handleMoveUp = () => {
    if (cardIndex < 5) {
      let fewerPlayersIndex = cardIndex + 1;
      setDoc(gameRef, { fewerPlayersIndex }, { merge: true });
    }
  };

  const handleMoveDown = () => {
    if (cardIndex > 0) {
      let fewerPlayersIndex = cardIndex - 1;
      setDoc(gameRef, { fewerPlayersIndex }, { merge: true });
    }
  };

  return (
    <div className="cardsSingle">
      <div className="label">Round {cardIndex + 1}</div>
      <div className="controls">
        <Button
          className="moveUp"
          disabled={cardIndex === 0}
          aria-label="reset"
          onClick={handleMoveDown}
        >
          <ChevronLeftIcon fontSize="small" />
        </Button>
        <Button
          disabled={cardIndex === 5}
          className="moveDown"
          aria-label="reset"
          onClick={handleMoveUp}
        >
          <ChevronRightIcon fontSize="small" />
        </Button>
      </div>
      <img src={image?.image} />
    </div>
  );
};
