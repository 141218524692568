import c001 from "../images/cards/001.png";
import c002 from "../images/cards/002.png";
import c003 from "../images/cards/003.png";
import c004 from "../images/cards/004.png";
import c005 from "../images/cards/005.png";
import c006 from "../images/cards/006.png";
import c007 from "../images/cards/007.png";
import c008 from "../images/cards/008.png";
import c009 from "../images/cards/009.png";
import c010 from "../images/cards/010.png";
import c011 from "../images/cards/011.png";
import c012 from "../images/cards/012.png";
import c013 from "../images/cards/013.png";
import c014 from "../images/cards/014.png";
import c015 from "../images/cards/015.png";
import c016 from "../images/cards/016.png";
import c017 from "../images/cards/017.png";
import c018 from "../images/cards/018.png";
import c019 from "../images/cards/019.png";
import c020 from "../images/cards/020.png";
import c021 from "../images/cards/021.png";
import c022 from "../images/cards/022.png";
import c023 from "../images/cards/023.png";
import c024 from "../images/cards/024.png";
import c025 from "../images/cards/025.png";
import c026 from "../images/cards/026.png";
import c027 from "../images/cards/027.png";
import c028 from "../images/cards/028.png";
import c029 from "../images/cards/029.png";
import c030 from "../images/cards/030.png";

export const gatewayBeginner = {
  1: { image: c001 },
  2: { image: c002 },
  3: { image: c003 },
  4: { image: c004 },
  5: { image: c005 },
  6: { image: c006 },
  7: { image: c007 },
  8: { image: c008 },
  9: { image: c009 },
  10: { image: c010 },
  11: { image: c011 },
  12: { image: c012 },
  13: { image: c013 },
  14: { image: c014 },
  15: { image: c015 },
  16: { image: c016 },
  17: { image: c017 },
  18: { image: c018 },
  19: { image: c019 },
  20: { image: c020 },
  21: { image: c021 },
  22: { image: c022 },
  23: { image: c023 },
  24: { image: c024 },
  25: { image: c025 },
  26: { image: c026 },
  27: { image: c027 },
  28: { image: c028 },
  29: { image: c029 },
  30: { image: c030 },
};
