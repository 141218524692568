import * as React from "react";
import { CardSingle } from "./CardSingle";

export const Cards = (props) => {
  const cardInfo = props.gameState?.playerData?.[props.player]?.cards;

  return (
    <div className="cardsContainer">
      <div className="unplayedCards">
        {cardInfo?.unplayed?.map((card, index) => {
          return (
            <div key={index}>
              <CardSingle
                position={index}
                cardId={card}
                gameState={props.gameState}
                player={props.player}
              />
            </div>
          );
        })}
      </div>
      <div className="usedCards">
        <div className="market">
          <div className="header">Market</div>
          {cardInfo?.market?.map((card, index) => {
            return (
              <div key={index}>
                <CardSingle
                  used={true}
                  position={index}
                  cardId={card}
                  gameState={props.gameState}
                  player={props.player}
                />
              </div>
            );
          })}
        </div>
        <div className="mission">
          <div className="header">Mission</div>
          {cardInfo?.mission?.map((card, index) => {
            return (
              <div key={index}>
                <CardSingle
                  used={true}
                  position={index}
                  cardId={card}
                  gameState={props.gameState}
                  player={props.player}
                />
              </div>
            );
          })}
        </div>
        <div className="farmyard">
          <div className="header">Farmyard</div>
          {cardInfo?.farmyard?.map((card, index) => {
            return (
              <div key={index}>
                <CardSingle
                  used={true}
                  position={index}
                  cardId={card}
                  gameState={props.gameState}
                  player={props.player}
                />
              </div>
            );
          })}
        </div>
        <div className="gateway">
          <div className="header">Gateway</div>
          {cardInfo?.gateway?.map((card, index) => {
            return (
              <div key={index}>
                <CardSingle
                  used={true}
                  position={index}
                  cardId={card}
                  gameState={props.gameState}
                  player={props.player}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
