import c401 from "../images/cards/401.png";
import c402 from "../images/cards/402.png";
import c403 from "../images/cards/403.png";
import c404 from "../images/cards/404.png";
import c405 from "../images/cards/405.png";
import c406 from "../images/cards/406.png";
import c407 from "../images/cards/407.png";
import c408 from "../images/cards/408.png";
import c409 from "../images/cards/409.png";
import c410 from "../images/cards/410.png";
import c411 from "../images/cards/411.png";
import c412 from "../images/cards/412.png";
import c413 from "../images/cards/413.png";
import c414 from "../images/cards/414.png";
import c415 from "../images/cards/415.png";
import c416 from "../images/cards/416.png";
import c417 from "../images/cards/417.png";
import c418 from "../images/cards/418.png";
import c419 from "../images/cards/419.png";
import c420 from "../images/cards/420.png";
import c421 from "../images/cards/421.png";
import c422 from "../images/cards/422.png";
import c423 from "../images/cards/423.png";
import c424 from "../images/cards/424.png";
import c425 from "../images/cards/425.png";
import c426 from "../images/cards/426.png";
import c427 from "../images/cards/427.png";
import c428 from "../images/cards/428.png";
import c429 from "../images/cards/429.png";
import c430 from "../images/cards/430.png";
import c431 from "../images/cards/431.png";
import c432 from "../images/cards/432.png";
import c433 from "../images/cards/433.png";
import c434 from "../images/cards/434.png";
import c435 from "../images/cards/435.png";

export const farmyardHops = {
  401: { image: c401 },
  402: { image: c402 },
  403: { image: c403 },
  404: { image: c404 },
  405: { image: c405 },
  406: { image: c406 },
  407: { image: c407 },
  408: { image: c408 },
  409: { image: c409 },
  410: { image: c410 },
  411: { image: c411 },
  412: { image: c412 },
  413: { image: c413 },
  414: { image: c414 },
  415: { image: c415 },
  416: { image: c416 },
  417: { image: c417 },
  418: { image: c418 },
  419: { image: c419 },
  420: { image: c420 },
  421: { image: c421 },
  422: { image: c422 },
  423: { image: c423 },
  424: { image: c424 },
  425: { image: c425 },
  426: { image: c426 },
  427: { image: c427 },
  428: { image: c428 },
  429: { image: c429 },
  430: { image: c430 },
  431: { image: c431 },
  432: { image: c432 },
  433: { image: c433 },
  434: { image: c434 },
  435: { image: c435 },
};
